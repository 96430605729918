$(document).ready(function () {
  const CLIENT_HOST = window.location.origin;
  const API_HOST = 'https://api.moodify.app';
  const udidKey = "moodify-udid";
  const spotifyIdKey = "moodify-spotifyId";

  let udid = localStorage.getItem(udidKey);
  let spotifyId = localStorage.getItem(spotifyIdKey);

  $.uuidv4 = function () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  };

  $("#login-button").click(function () {
    let endpoint = "https://accounts.spotify.com/authorize";
    let clientID = "9f0fe657fb2244b392b8f8dd5c6b89dc";
    let redirectUri = `${CLIENT_HOST}/callback.html`;
    let scope = "user-read-recently-played,playlist-modify-public,user-top-read,user-read-private,ugc-image-upload";

    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('event', 'login-button-click', {
      'event_category': 'engagement',
    });

    let url = endpoint + "?client_id=" + encodeURIComponent(clientID) +
      "&redirect_uri=" + encodeURIComponent(redirectUri) +
      "&scope=" + encodeURIComponent(scope) +
      "&response_type=code&show_dialog=true";

    window.location.replace(url);
  });

  if (udid == null) {
    udid = $.uuidv4();
    localStorage.setItem(udidKey, udid);
  }

  let requireLogin = udid == null || spotifyId == null;

  if (requireLogin) {
    $("#login-container").show();
    $("#app-container").hide();
    $("#footer").hide();
    return;
  } else {
    $("#login-container").hide();
    $("#app-container").show();
    $("#footer").show();
  }

  const historicalTrackCount = 15;
  const recentTrackCount = 3;

  const minTempo = 50;
  const maxTempo = 200;

  $.logout = function () {
    localStorage.removeItem(udidKey);
    localStorage.removeItem(spotifyIdKey);
    location.reload();
    return;
  }

  $("#logout").click($.logout);

  let profileEndpoint = `${API_HOST}/profile`;
  let profileParams = {
    udid: udid,
    userId: spotifyId
  };

  $.checkLoginStatus = function(json) {
    if (json.success == false && json.message.includes('not authorized')) {
      $.logout();
    }
  }

  // Populate profile data.
  $.get(profileEndpoint, profileParams, function (response) {
    let json = JSON.parse(response);
    $.checkLoginStatus(json);

    let data = json.data;
    let imageSize = 25;

    let image = $('<img>', {
      class: 'profile-image inline',
      src: data.imageUrl,
      width: imageSize,
      height: imageSize
    });

    let name = $('<p>', {
      class: 'profile-name inline',
      text: data.name
    });

    $("#profile-inner").append(image);
    $("#profile-inner").append(name);

  }).fail(function (response) {
    let json = JSON.parse(response.responseText);
    $.checkLoginStatus(json);
  });

  $("#profile-inner").click(function () {
    $(".dropdown-content").toggle();
  });

  /**
   * Creates clickable tile for artist/track.
   * 
   * @param data Artist/track data.
   * @param type Data type as 'artist' or 'track'.
   * @param imageSize Requested size for artist/track image.
   * @return Clickable artist/track tile as div object.
   */
  $.createTrackArtistTile = function (data, type, imageSize) {
    let image = $('<img>', {
      class: 'list-item-image',
      src: data.imageUrl,
      width: imageSize,
      height: imageSize
    });

    let imageDiv = $('<div>', {
      class: 'col-sm-3 col-lg-2',
      html: image
    });

    imageDiv.append(image);

    let nameDiv = $('<div>', {
      class: 'col-sm-9 col-lg-10'
    });

    let trackNameDiv = $('<div>', {
      class: 'row list-item-track-name col-sm-12',
      text: data.name
    });

    let artistNames = data.artists.map(artist => artist.name).join(", ");
    let artistNamesDiv = $('<div>', {
      class: 'row list-item-artist-name col-sm-12',
      text: artistNames
    });

    nameDiv.append(trackNameDiv);
    nameDiv.append(artistNamesDiv);

    let listItemDiv = $('<div>', {
      class: 'row list-item'
    });

    listItemDiv.append(imageDiv);
    listItemDiv.append(nameDiv);

    let clickableListItemDiv = $('<a>', {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: `https://open.spotify.com/${type}/${data.id}`
    });

    clickableListItemDiv.append(listItemDiv);

    return clickableListItemDiv;
  }

  /**
   * Populates top artists and tracks.
   * 
   * @param type artist or track
   */
  $.populateTopArtistTracks = function (type, limit) {
    let terms = ["long", "medium", "short"];
    terms.forEach(function (term) {
      let topArtistTrackEndpoint = `${API_HOST}/top-${type}s`;
      let timeRange = `${term}_term`;
      let topArtistTrackParams = {
        udid: udid,
        userId: spotifyId,
        timeRange: timeRange
      };

      $.get(topArtistTrackEndpoint, topArtistTrackParams, function (response) {
        let json = JSON.parse(response);
        let data = json.data.slice(0, limit);
        let divId = `#top-${type}-${term}-term`;
        let imageSize = 45;
        let blockDataDiv = $('<div>', {
          class: 'col-sm-12'
        });

        data.forEach(function (object) {
          let clickableListItemDiv = $.createTrackArtistTile(object, type, imageSize);
          blockDataDiv.append(clickableListItemDiv);
        });

        $(divId).append(blockDataDiv);
      });
    });
  }

  let limit = 5;
  $.populateTopArtistTracks("track", limit);

  /**
   * Shows a popup with given message.
   * 
   * @param title       Popup title.
   * @param message     Popup message.
   * @param buttonText  Text for button.
   * @param uri         URI that action button will redirect to.
   */
  $.popup = function (title, message, buttonText, uri) {
    $("#popup-title").text(title);
    $("#popup-message").text(message);

    let spotifyButton = $("#popup-spotify-button");
    let button = $("#popup-ok-button");

    if (buttonText != "") {
      button.text(buttonText);
      button.show();
    } else {
      button.hide();
    }

    if (uri != undefined && uri != "") {
      spotifyButton.attr("href", uri);
      spotifyButton.show();
    } else {
      spotifyButton.hide();
    }

    $("#popup-modal").modal("show");
  }

  /**
   * Search
   */
  $.searchResultArtists = [];
  $.searchResultTracks = [];
  $.seeds = [];
  var jqxhr = { abort: function () { } };
  let searchBox = $("#recommendation-seed-search");
  let searchResultBox = $("#recommendation-search-result-box");

  searchBox.on("input", function () {
    let input = searchBox.val();
    if (input.trim() != "") {
      searchResultBox.show();
      let searchEndpoint = `${API_HOST}/search`;
      let searchParams = {
        udid: udid,
        userId: spotifyId,
        query: input
      };

      jqxhr.abort();
      jqxhr = $.get(searchEndpoint, searchParams, function (response) {
        let json = JSON.parse(response);
        let data = json.data;
        $.searchResultArtists = [];
        $.searchResultTracks = [];

        data.forEach(function (item) {
          if (item.itemType == "artist") {
            $.searchResultArtists.push(item);
          } else if (item.itemType == "track") {
            $.searchResultTracks.push(item);
          }
        });

        $.updateSearchResults($.searchResultArtists, "artist");
        $.updateSearchResults($.searchResultTracks, "track");
      });
    } else {
      $.clearSearch();
    }
  });

  $.clearSearch = function () {
    searchResultBox.hide();
    $.searchResultArtists = [];
    $.searchResultTracks = [];
    $("#recommendation-search-result-artist").empty();
    $("#recommendation-search-result-track").empty();
    searchBox.val("");
  };

  $.updateSearchResults = function (items, itemType) {
    let listId = `#recommendation-search-result-${itemType}`;
    var listData = "";
    let imageSize = 35;

    items.forEach(function (item) {
      let extraData = "";
      if (itemType == "track") {
        extraData = `, ${item.extra}`;
      }
      let imageHtml = `<image src='${item.imageUrl}' class='list-item-image-${itemType}' width='${imageSize}' heigth='${imageSize}'></div>`;

      listData += `<li id='search-result-${item.id}' class='list-item-search-result' data-type='${itemType}'>${imageHtml}${item.name + extraData}</li>`;
    });

    $(listId).html(listData);

    items.forEach(function (item) {
      $(`#search-result-${item.id}`).click(function () {
        let uri = `spotify:${itemType}:${item.id}`;
        if ($.seeds.length == 5) {
          $.popup("Oops", "You can specify at most five artists and tracks.", "OK");
        } else if (!$.seeds.includes(item)) {
          $.addSeed(item);
        }
        $.clearSearch();
      });
    });
  };

  $.addSeed = function (item) {
    $.seeds.push(item);
    $.populateSeedList();
  };

  $.populateSeedList = function () {
    let htmlList = $.seeds.map(function (item) {
      let divId = `seed-${item.id}`;
      let html = `<div id='${divId}' class='button selected-seed inline'>${item.name}<span class='button-remove'>×</span></div>`;
      return html;
    });

    let seedHtml = htmlList.join("");
    $("#recommendation-seed-selected").html(seedHtml);

    $.seeds.forEach(function (item) {
      let divId = `#seed-${item.id}`;
      $(divId).click(function () {
        let index = $.seeds.indexOf(item)
        if (index > -1) {
          $.seeds.splice(index, 1);
          $.populateSeedList();
        }
      });
    });
  }

  // Fill default artists data.
  let defaultArtistsEndpoint = `${API_HOST}/default-artists`;
  let defaultArtistsParams = {
    udid: udid,
    userId: spotifyId
  };

  $.get(defaultArtistsEndpoint, defaultArtistsParams, function (response) {
    let json = JSON.parse(response);
    let data = json.data;
    data.forEach(function (item) {
      $.addSeed(item);
    });
  });

  let recommendationButton = $("#recommendation-create-button");
  recommendationButton.click(
    () => {
      let callback = (response) => {
        recommendationButton.html("CHECK SPOTIFY");
        setTimeout(
          () => {
            recommendationButton.html("CREATE PLAYLIST");
            let json = JSON.parse(response);
            let playlistId = json.data.playlistId;
            let playlistUri = `spotify:user:${spotifyId}:playlist:${playlistId}`;
            $.popup("Yaay!", "Check your Spotify for Discover Moodify playlist!", "Stay on Page", playlistUri);
          },
          500);
      };

      let fallback = () => {
        recommendationButton.html("CREATE PLAYLIST");
      };

      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('event', 'create-playlist', {
        'event_category': 'recommendation',
      });

      recommendationButton.html("PLEASE WAIT");
      $.createPlaylist(callback, fallback);
    }
  );

  $.getAudioFeatureValue = function (name) {
    return parseFloat($(`#range-${name}`).val());
  };

  $.convertTempoToBPM = function (rawValue) {
    var value = rawValue * (maxTempo - minTempo) + minTempo;

    if (value < minTempo) {
      value = minTempo;
    } else if (value > maxTempo) {
      value = maxTempo;
    }

    return value;
  }

  $.getAudioFeatureValueForTempo = function () {
    let rawValue = $.getAudioFeatureValue("tempo");
    let value = $.convertTempoToBPM(rawValue);

    return value;
  }

  $.getSeedIdList = function (type) {
    return $.seeds
      .filter((item) => item.itemType == type)
      .map((item) => item.id);
  };

  $.postJSON = function (url, data, callback) {
    return jQuery.ajax({
      'type': 'POST',
      'url': url,
      'data': JSON.stringify(data),
      'success': (response) => callback(response),
      'error': () => console.log('Request failed.')
    });
  };

  $.createPlaylist = function (callback, fallback) {
    if ($.seeds.length == 0) {
      $.popup("Oops", "You should specify at least one artist or track.", "OK");
      fallback();
    } else if ($.seeds.length > 5) {
      $.popup("Oops", "You can specify at most five artists and tracks.", "OK");
      fallback();
    } else {
      let url = `${API_HOST}/recommendation?udid=${udid}&userId=${spotifyId}`;
      let data = {
        "seedArtistIdList": $.getSeedIdList("artist"),
        "seedTrackIdList": $.getSeedIdList("track"),
        "acousticness": $.getAudioFeatureValue("acousticness"),
        "instrumentalness": $.getAudioFeatureValue("instrumentalness"),
        "tempo": $.getAudioFeatureValueForTempo(),
        "danceability": $.getAudioFeatureValue("danceability"),
        "energy": $.getAudioFeatureValue("energy"),
        "valence": $.getAudioFeatureValue("valence")
      };

      $.postJSON(url, data, callback);
    }
  }

  /**
   * Chart
   */
  let ctx = $("#stats-chart");

  var chart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: ["Acousticness", "Instrumentalness", "Tempo", "Danceability", "Energy", "Mood"],
      datasets: [{
        label: 'Last Fifteen Songs',
        borderColor: 'rgba(170, 170, 170, 1)',
        backgroundColor: 'rgba(170, 170, 170, 0.75)',
        borderWidth: 2.5
      },
      {
        label: 'Last Three Songs',
        borderColor: 'rgba(47, 213, 102, 1)',
        backgroundColor: 'rgba(47, 213, 102, 0.75)',
        borderWidth: 2.5
      }]
    },
    options: {
      legend: {
        labels: {
          fontColor: 'rgba(255, 255, 255, 0.9)',
          fontFamily: "'Lato', 'sans-serif'",
          fontSize: 12
        }
      },
      elements: {
        line: {
          fill: false
        },
        point: {
          pointStyle: "star"
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            fontFamily: "'Lato', 'sans-serif'",
            fontColor: 'rgba(255, 255, 255, 0.65)',
            max: 100,
            padding: 20
          },
          gridLines: {
            display: false
          }
        }],
        xAxes: [{
          ticks: {
            beginAtZero: true,
            fontFamily: "'Lato', 'sans-serif'",
            fontColor: 'rgba(255, 255, 255, 0.9)',
            fontSize: 14,
            padding: 20
          },
          gridLines: {
            display: false
          }
        }]
      }
    }
  });

  $.getField = function (json, field) {
    let rawValue = parseFloat(json[field]);
    let value = rawValue * 100;
    let result = value.toFixed(0);

    return result;
  }

  $.getTempo = function (json) {
    let rawValue = parseFloat(json["tempo"]);
    let value = (rawValue - minTempo) / (maxTempo - minTempo) * 100;
    var result = value.toFixed(0);

    if (result < 0) {
      result = 0;
    } else if (result > 100) {
      result = 100;
    }
    return result;
  }

  $.drawChart = function (response, chartIndex) {
    let json = jQuery.parseJSON(response);
    let data = json.data;
    let acousticness = $.getField(data, "acousticness");
    let instrumentalness = $.getField(data, "instrumentalness");
    let tempo = $.getTempo(data);
    let danceability = $.getField(data, "danceability");
    let energy = $.getField(data, "energy");
    let valence = $.getField(data, "valence");

    chart.data.datasets[chartIndex].data = [acousticness, instrumentalness, tempo,
      danceability, energy, valence];
    chart.update();
  }

  let trendlineEndpoint = `${API_HOST}/trendline`;

  let historicTrendlineParams = {
    udid: udid,
    userId: spotifyId,
    numTracks: historicalTrackCount
  };

  let recentTrendlineParams = {
    udid: udid,
    userId: spotifyId,
    numTracks: recentTrackCount
  };

  // Update the historical trendline chart.
  $.get(trendlineEndpoint, historicTrendlineParams, function (response) {
    $.drawChart(response, 0);
  });

  $.getFeatureDetailText = function (value, low, mid, high) {
    var text = "";

    if (value <= 0.3) text = low;
    else if (value <= 0.6) text = mid;
    else text = high;

    return text;
  }

  $.updateFeatureDetail = function (field, value) {
    var text = "";

    switch (field) {
      case 'acousticness':
        text = $.getFeatureDetailText(value, 'Digital', 'Mix', 'Analog');
        break;

      case 'instrumentalness':
        text = $.getFeatureDetailText(value, 'Vocal', 'Mix', 'No Vocal');
        break;

      case 'danceability':
        text = $.getFeatureDetailText(value, 'Not Proper', 'OK', 'Great for Dance');
        break;

      case 'energy':
        text = $.getFeatureDetailText(value, 'Calm', 'Fun', 'Energizer');
        break;

      case 'valence':
        text = $.getFeatureDetailText(value, 'Depressive', 'Cheerful', 'Joyful');
        break;

      case 'tempo':
        let tempo = parseFloat(value).toFixed(1);
        let bpm = $.convertTempoToBPM(tempo);
        text = `${bpm} BPM`;
        break;
    }

    $(`#feature-detail-${field}`).text(`${text}`);
  }

  let features = ['acousticness', 'instrumentalness', 'tempo', 'danceability', 'energy', 'valence'];

  features.forEach(function (feature) {
    let sliderElement = $(`#range-${feature}`);
    sliderElement.on('input change', () => {
      $.updateFeatureDetail(feature, sliderElement.val());
    });
  });

  $.updateRangePosition = function (json, field) {
    var value = parseFloat(json[field]).toFixed(1);

    if (field == "tempo") {
      value = (value - minTempo) / (maxTempo - minTempo);
      if (value < 0) {
        value = 0;
      } else if (value > 1) {
        value = 1;
      }
    }

    $.updateFeatureDetail(field, value);

    $(`#range-${field}`).val(value);
  }

  // Update the recent trendline chart.
  $.get(trendlineEndpoint, recentTrendlineParams, function (response) {
    $.drawChart(response, 1);
    let json = jQuery.parseJSON(response);
    let data = json.data;
    $.updateRangePosition(data, "acousticness");
    $.updateRangePosition(data, "instrumentalness");
    $.updateRangePosition(data, "tempo");
    $.updateRangePosition(data, "danceability");
    $.updateRangePosition(data, "energy");
    $.updateRangePosition(data, "valence");
  });

});
